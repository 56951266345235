import { React, useState } from 'react';
import { navigate } from "gatsby"
import MessageOverlay from '../components/messageOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationTriangle, faThumbsUp, faSave, faKey, faLink, faPhone, faEnvelope, faUser, faBuilding, faGlobeEurope, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import LayoutSecondary from '../components/layout/LayoutSecondary';


const Success = ({ location, type = 'card' }) => {
  console.log(location.state);
  let receivedFirstName, receivedSurname;

  const [pwError, setPwError] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const submitButtonStyle = 'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
  const submitButtonStyleDisabled = 'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-300 opacity-50 cursor-not-allowed';
  const inputStyleNormal = "border-4 hover:bg-yellow-200 my-2 p-2 focus:outline-none focus:ring focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-grey-300 rounded";
  const inputStyleError = "border-4 hover:bg-yellow-200 my-2 p-2 focus:outline-none focus:ring focus:border-red-500 block w-full shadow-sm sm:text-sm border-red-300 rounded";

  const formStyle = inputStyleNormal;
  const formStyleError = inputStyleError;
  const params = new URLSearchParams(location.search);
  //const receivedName = params.get("name");
  const receivedStrapiId = params.get("id");
  const tooltip= (
    <div className="absolute top-10 right-0">
      <div className="bg-black text-white text-xs rounded py-1 px-4 right-0 bottom-full">
        Example, if you enter 'acmeltd', your application will be available at https://acmeltd-admin.punch-in.co.uk
        <svg className="absolute text-black h-2 left-0 ml-3 top-full" x="0px" y="0px" viewBox="0 0 255 255" space="preserve"><polygon className="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
      </div>
    </div>
  )
  const name = params.get("name");
  if(name){
    [receivedFirstName, receivedSurname] = (params.get("name")).split(' ') || ['', ''];
    // setFormStatus( form => {
    //   let newForm = form;
    //   newForm.firstname = receivedFirstName;
    //   newFo=>rm.lastname = receivedSurname;
    //   return newForm;
    // });
  }
  //[receivedFirstName, receivedSurname] = (params.get("name")).split(' ');
  const receivedEmail = params.get("email") || '';
  if(receivedEmail){
    // setFormStatus( form => {
    //   let newForm = form;
    //   newForm.email = receivedEmail;
    //   return newForm;
    // });
  }
  //console.log(receivedStrapiId + ' strapi id');

  const [showOverlay, setShowOverlay] = useState(true);
  /*
  setDomainError(false);
  setDomainGood(true);
  */
  const [domainError, setDomainError] = useState(false);
  const [domainGood, setDomainGood] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [error, setError] = useState({
    status: false,
    message:''
  });

  const [formStatus, setFormStatus] = useState({
    firstname: {
      error:false,
      value: receivedFirstName ? receivedFirstName : null
    },
    lastname: {
      error:false,
      value: receivedSurname ? receivedSurname : null
    },
    company: {
      error:false,
      value: null
    },
    email: {
      error:false,
      value: receivedEmail ? receivedEmail : null
    },
    phone: {
      error:false,
      value: null
    },
    streetaddress: {
      error:false,
      value: null
    },
    city: {
      error:false,
      value: null
    },
    postcode: {
      error:false,
      value: null
    },
    password: {
      error:false,
      value: null
    },
    subdomain: {
      error:false,
      value: null
    }
  });

  const checkPasswordPattern = ( {target} ) => {
    console.log('executing check pw pattern');
    const lowerCase = /[a-z]/.test(target.value);
    const upperCase = /[A-Z]/.test(target.value);
    const num = /[0-9]/.test(target.value);
    if(lowerCase && upperCase && num) {
      //validated
      console.log('validate');
    } else {
      // pattern not respected
      console.log('not validated', lowerCase, upperCase, num);
      setError({
        status: true,
        message: 'The password must contain at least an upper case, lower case and a number'
      });
      setShowOverlay(true);
      setFormStatus({...formStatus, password: {error: true, value: null}})
    }
  }

  const handleFormChange = ( {target} ) => {
    // formStatus[target.name].value = target.value;
    let newForm = formStatus;
    newForm[target.name] = {
      value: target.value,
      error: false
    };
    //console.log(newForm);
    if(target.name === 'subdomain' && target.value && target.value.length < 4) {
      newForm.subdomain.error = true;
    }
    setFormStatus( {...newForm});
    console.log(formStatus);
  }

  const handleSubdomainChange = ( {target} ) => {
    // Firstly check if subdomain exists, over 4 chars
    console.log(target, typeof target);
    const checkNumber = /^[a-z]+$/;
    //console.log(`${target.value}=${/[^a-z]/.test(target.value)}`);
    if( /[^a-z]/.test(target.value) ){
      console.log('non-alpha');
      setError({
        status: true,
        message: 'The domain name must contain only lowercase letters from a to z'
      });
      setShowOverlay(true);

    } else {
      console.log('ok');
      if(target.value.length > 4){
        // fetch subdomain from api

        const options = {
          method: 'POST',
          body: JSON.stringify({domain:target.value.trim().toLowerCase()}),
          mode: 'cors',
          headers:{'content-type': 'application/json'},
          redirect: 'follow',
        };

        fetch('https://api.punch-in.co.uk/check-domain', options)
          .then(res =>{
            //console.log(res);
            if(res.status >199 && res.status < 300){
              // Login successful, hide login
              setFormStatus({
                ...formStatus,
                subdomain: {
                      error:false,
                      value: target.value
                    }
              });
              setDomainError(false);
              setDomainGood(true);
            } else {
              //console.log('we are in the else');
              //console.log(res);
              setDomainError(true);
              setDomainGood(false);
               setFormStatus({
                 ...formStatus,
                 subdomain: {
                       error:true,
                       value: null
                     }
               });
               //setShowOverlay(true);
               //return null;
            }
        }).catch(err => {
          console.error(err);
          setError({
            status: true,
            message: 'The server returned an unexpected response'
          });
          setShowOverlay(true);
        })

      }
      let newForm = formStatus;
      newForm[target.name].value = target.value;
      //console.log(newForm);
      if(target.name === 'subdomain' && target.value && target.value.length < 4) {
        newForm.subdomain.error = true;
      }
      if(!target.value){
        newForm[target.name].error = true;
      }

      setFormStatus( newForm);
      ///////////

    }
  }

  const confirmPassword = ( {target}) => {
    //console.log(typeof formStatus.password.value, formStatus.password.value, typeof target.value, target.value);
    if(formStatus.password.value === target.value){
      formStatus.password.error = false;
      setFormStatus(formStatus);
      setPwError(false);
      //console.log(formStatus.password);
    } else {
      formStatus.password.error = true;
      setFormStatus(formStatus);
      setPwError(true);
      //console.log(formStatus.password);
    }
  }

  const handleSubmit = ( event ) => {
    event.preventDefault();
    if(!disableSubmit) {
      setDisableSubmit(true);
      let formObj = {
        _id: receivedStrapiId,
        firstname: formStatus.firstname.value,
        lastname: formStatus.lastname.value,
        name: `${formStatus.firstname.value} ${formStatus.lastname.value}`,
        subdomain: formStatus.subdomain.value,
        phone: formStatus.phone.value,
        shipping: {
          name: formStatus.company.value,
          address: {
            line1: formStatus.streetaddress.value,
            city: formStatus.city.value,
            postal_code: formStatus.postcode.value,
            country: 'GB'
          }
        },
        company: formStatus.company.value,
        email: formStatus.email.value,
        password: formStatus.password.value,
        type: location.state.type,
        price: location.state.price,
        maxUsers: location.state.maxUsers
      }
      let isFormReady = true;
      for (const val of Object.keys(formStatus)) {
        console.log(`${val} = ${formStatus[val].value}`);
        if(!formStatus[val].value) {
          //console.log('we are setting an error');
          //console.log
          setError({
            status: true,
            message: `form incomplete, missing or incorrect ${val}`
          });
          setShowOverlay(true);
          setDisableSubmit(false);
          let tempFormStatus = formStatus;
          tempFormStatus[val] = { error: true, value: null}
          setFormStatus({...tempFormStatus});
          isFormReady = false;
          //return
          console.log(isFormReady);
        }
        if(formObj.subdomain && formObj.subdomain.length < 5){
          setError({
            status: true,
            message: `subdomain too short, at least 5 characters`
          });
          setShowOverlay(true);
          setDisableSubmit(false);
          //return;
          isFormReady = false;
        }
      }
      // formObj.append('firstname', formStatus.firstname.value);
      // formObj.append('lastname', formStatus.lastname.value);
      // formObj.append('streetaddress', formStatus.streetaddress.value);
      // formObj.append('city', formStatus.city.value);
      // formObj.append('postcode', formStatus.postcode.value);
      // formObj.append('company', formStatus.company.value);
      // formObj.append('email', formStatus.email.value);
      console.log(formObj);

      if( pwError === true) isFormReady = false;
      console.log(`the isFormReady var is : ${isFormReady}`);
      if(isFormReady) {
        const options = {
          method: 'POST',
          body: JSON.stringify(formObj),
          mode: 'cors',
          headers:{'content-type': 'application/json'},
          redirect: 'follow',
        };
        fetch('https://api.punch-in.co.uk/update-customer', options)
          .then(res =>{
            //console.log(res);
            if(res.status >199 && res.status < 300){
              // Login successful, hide login
              return res.json();
            } else {
              //console.log('we are in the else');
              //console.log(res);
               setError({
                 status: true,
                 message: 'The server returned an unexpected response'
               });
               setShowOverlay(true);
               setDisableSubmit(false);
               //return null;
            }
          })
          .then(response =>{
            /// this is the userDetails data
            if(response) {
              console.log(response);
              navigate(`/app/profile`, { state: response})
            }

        }).catch(err => {
          console.error(err);
          setError({
            status: true,
            message: 'The server returned an unexpected response'
          });
          setShowOverlay(true);
          setDisableSubmit(false);
        })
      } else {
        setError({
          status: true,
          message: 'Please revise your data. The form is not ready to be sent'
        });
        setShowOverlay(true);
        setDisableSubmit(false);
      }

    } else {
      setError({
        status: true,
        message: 'You have submitted this form already. Please wait.'
      });
      setShowOverlay(true);
    }

  }

  const closeOverlay = () => {
    //console.log('closeOverlay function!');
    setShowOverlay(false);
    //console.log(`ShowOverlay is ${showOverlay}`);
  }

  const errorOverlay = (
    <MessageOverlay type="error" text={error.message} close={closeOverlay} />
  )

  const successOverlay = (
    <MessageOverlay type="success" text="you've signed up, now complete your registration" close={closeOverlay} />
  )

  const title = 'You have subscribed successfully!';
  const description = 'You have subscribed to PunchIn, now we need your data to start setting up your environment';

  return (
    <LayoutSecondary title={title} description={description} article={false}>
      {showOverlay && error.status && errorOverlay}
      {showOverlay && !error.status && successOverlay}
      <section id="signup" className="pt-20 md:pt-20">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-full">
            <h1 className="block text-4xl lg:text-5xl xl:text-6xl text-indigo-700 font-bold leading-none w-full">
              Now complete your registration
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Thanks for the confidence in us. Sign up and start using PunchIn
              right away. You can be all set in 5 min.
            </p>
          </div>
        </div>
        <div className="container py-12 mx-auto px-8 lg:flex">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Your details</h3>
                <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleSubmit}>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faUser} /></span> First name
                        </label>
                        <input
                          defaultValue={receivedFirstName}
                          type="text"
                          name="firstname"
                          id="firstname"
                          autoComplete="given-name"
                          className={formStatus.firstname.error ? inputStyleError : inputStyleNormal}
                          onChange={handleFormChange}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faUser} /></span> Last name
                        </label>
                        <input
                          defaultValue={receivedSurname}
                          type="text"
                          name="lastname"
                          id="lastname"
                          autoComplete="family-name"
                          className={formStatus.lastname.error ? inputStyleError : inputStyleNormal}
                          onChange={handleFormChange}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faEnvelope} /></span> Email address
                        </label>
                        <input
                          defaultValue={receivedEmail}
                          type="email"
                          name="email"
                          id="email-address"
                          autoComplete="email"
                          className={formStatus.email.error ? inputStyleError : inputStyleNormal}
                          onChange={handleFormChange}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faPhone} /></span> Telephone
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          autoComplete="tel"
                          className={formStatus.phone.error ? inputStyleError : inputStyleNormal}
                          onChange={handleFormChange}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faKey} /></span>  Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          autoComplete="new-password"
                          className={ formStatus && formStatus.password && formStatus.password.error ? inputStyleError : inputStyleNormal }
                          onChange={handleFormChange}
                          onBlur={checkPasswordPattern}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="confirmpassword" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faKey} /><FontAwesomeIcon icon={faKey} /></span>  Confirm Password
                            { pwError && formStatus.password.value && formStatus.password.value.length > 0 ? (<span className="text-red-700 pl-3 font-bold"> <FontAwesomeIcon icon={faExclamationTriangle} />no match!</span>) : (<span className="text-green-700 pl-3 font-bold"> <FontAwesomeIcon icon={faThumbsUp} />good!</span>)}
                        </label>
                        <input
                          type="password"
                          name="confirmpassword"
                          id="confirmpassword"
                          autoComplete="off"
                          className={ formStatus.password.error ? inputStyleError : inputStyleNormal}
                          onChange={confirmPassword}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faBuilding} /></span> Company name
                        </label>
                        <input
                          type="text"
                          name="company"
                          id="company"
                          autoComplete="organization"
                          className={formStatus.company.error ? inputStyleError : inputStyleNormal}
                          onChange={handleFormChange}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="streetaddress" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faMapMarkedAlt} /></span>  Company street address
                        </label>
                        <input
                          type="text"
                          name="streetaddress"
                          id="streetaddress"
                          autoComplete="street-address"
                          className={formStatus.streetaddress.error ? inputStyleError : inputStyleNormal}
                          onChange={handleFormChange}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faMapMarkedAlt} /></span>  Company city
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          className={formStatus.city.error ? inputStyleError : inputStyleNormal}
                          onChange={handleFormChange}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="postcode" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faMapMarkedAlt} /></span>  Company post code
                        </label>
                        <input
                          type="text"
                          name="postcode"
                          id="postcode"
                          autoComplete="postal-code"
                          className={formStatus.postcode.error ? inputStyleError : inputStyleNormal}
                          onChange={handleFormChange}
                        />
                      </div>

                      <div className="col-span-6 md:col-span-3">
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faGlobeEurope} /></span>  Country / Region
                        </label>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country"
                          className="mt-1 bg-yellow-100 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option>United Kingdom only</option>
                        </select>
                      </div>

                      <div className="relative col-span-6 md:col-span-3" onMouseOver={ () => setShowTooltip(true)} onMouseOut={ () => setShowTooltip(false)}>
                        <label htmlFor="subdomain" className="block text-sm font-medium text-gray-700">
                          <span className="text-indigo-700"><FontAwesomeIcon icon={faLink} /></span>  Your subdomain name (i.e. how you will access your application)
                        </label>
                          <input
                            type="text"
                            name="subdomain"
                            id="subdomain"
                            autoComplete="off"
                            className={ domainError || formStatus.subdomain.error ? formStyleError : formStyle }
                            onChange={handleSubdomainChange}
                          />
                        { domainError && (<p className="font-bold text-red-700"><FontAwesomeIcon icon={faExclamationTriangle} /> Error: name already taken</p>)}
                        { domainGood && (<p className="font-bold text-green-700"><FontAwesomeIcon icon={faThumbsUp} /> Good! Domain free!</p>)}

                        {showTooltip && tooltip}

                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className={ disableSubmit ? submitButtonStyleDisabled : submitButtonStyle}
                    >
                      <FontAwesomeIcon icon={ disableSubmit ? faSpinner : faSave} className={ disableSubmit ? 'animate-spin' : ''} /> &nbsp;Save info
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </LayoutSecondary>
  );
}

export default Success;
